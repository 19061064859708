import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { useLinkComponent } from '@marty-js/design/src/utils/component';
import { AffiliateLink, Link } from '@marty-js/design/src/atoms/link';

interface Props {
  title?: string;
  shortTitle?: string;
  url?: string;
}

const PromotionalBannerContainer = styled.div`
  display: block;
  background-color: ${(props) => (props.theme.isDark ? props.theme.palette.darkBlack : props.theme.palette.blue)};
  padding: 6px;
  text-align: center;
  margin: 0;
`;

const Title = styled.div`
  display: none;
  color: ${(props) => props.theme.palette.white};
  font-weight: bold;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
    `,
  )}
`;

const ShortTitle = styled.div`
  display: block;
  color: ${(props) => props.theme.palette.white};
  font-weight: bold;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )};
`;

export const PromotionalBanner: React.FC<Props> = ({ title, shortTitle, url }: Props) => {
  const LinkComponent = useLinkComponent();
  const isExternalLink = useMemo(() => {
    const patternIntWithNDD = /^https?:\/\/(?:^.)*\.clubic\.com\//;
    const patternIntWithoutNDD = /^\//;

    return !(patternIntWithNDD.test(url) || patternIntWithoutNDD.test(url));
  }, [url]);

  const link = {
    href: url,
    as: isExternalLink ? AffiliateLink : Link,
  };

  return (
    <PromotionalBannerContainer>
      <LinkComponent {...link}>
        <Title>{title}</Title>
        <ShortTitle>{shortTitle}</ShortTitle>
      </LinkComponent>
    </PromotionalBannerContainer>
  );
};
